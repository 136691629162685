const BASE_URL = 'https://api.yiedl.ai/yiedl/v1/';
// const BASE_URL = 'http://localhost:8086/yiedl/v1/';

const ENDPOINTS = {
  ping: 'ping',
  getPriceFeed: 'getPriceFeed',
  getNextAvailableTimestamp: 'getNextAvailableTimestamp',
  getMarketplaceTableData: 'getMarketplaceTableData',
  getAssetStrengths: 'getAssetStrengths',
  getProductData: 'getProductData',
  getProductHistoricalReturns: 'getProductHistoricalReturns',
  getBtcHistoricalReturns: 'getBtcHistoricalReturns',
  getLatestAllocation: 'getLatestAllocation',
  getCompetitionTableData: 'getCompetitionTableData',
  getCompetitionHistoricalPredictorCounts: 'getCompetitionHistoricalPredictorCounts',
  getCompetitionHistoricalStakes: 'getCompetitionHistoricalStakes',
  getCompetitionHistoricalRewards: 'getCompetitionHistoricalRewards',
  getParticipantData: 'getParticipantData',
  setClickRecord: 'setClickRecord',
};

function buildQueryString(params: { [key: string]: any }): string {
  const queryString = new URLSearchParams(params).toString();
  return queryString ? `?${queryString}` : '';
}

async function fetchWithRetry(endpoint: string, isSetter = false, retries = 20, delay = 10000): Promise<any> {
  const url = `${BASE_URL}${endpoint}`;
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (!isSetter) {
        return await response.json();
      } else {
        return response;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('=== Error ===', url);
        console.error(`Attempt ${attempt} failed: ${error.message}`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        console.error(`Attempt ${attempt} failed with an unknown error.`);
      }
      if (attempt < retries) {
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        throw new Error(`Failed to fetch ${url} after ${retries} attempts`);
      }
    }
  }
}

export async function ping(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.ping);
}

export async function getPriceFeed(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getPriceFeed);
}

export async function getNextAvailableTimestamp(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getNextAvailableTimestamp);
}

export async function getMarketplaceTableData(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getMarketplaceTableData);
}

export async function getAssetStrengths(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getAssetStrengths);
}

export async function getProductData(productId: string): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getProductData + buildQueryString({ productId }));
}

export async function getProductHistoricalReturns(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getProductHistoricalReturns);
}

export async function getBtcHistoricalReturns(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getBtcHistoricalReturns);
}

export async function getLatestAllocation(productId: string): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getLatestAllocation + buildQueryString({ productId }));
}

export async function getCompetitionTableData(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getCompetitionTableData);
}

export async function getCompetitionHistoricalPredictorCounts(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getCompetitionHistoricalPredictorCounts);
}

export async function getCompetitionHistoricalStakes(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getCompetitionHistoricalStakes);
}

export async function getCompetitionHistoricalRewards(): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getCompetitionHistoricalRewards);
}

export async function getParticipantData(competitionName: string, address: string): Promise<any> {
  return fetchWithRetry(ENDPOINTS.getParticipantData + buildQueryString({ competitionName, address }));
}

export async function setClickRecord(productId: number, address: string): Promise<any> {
  return fetchWithRetry(ENDPOINTS.setClickRecord + buildQueryString({ productId, address }), true);
}
