import React, { useState } from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as S from './DashboardPages/DashboardPage.styles';
import * as ICS from './../components/vaults-dashboard/infoCard/InfoCard.styles';
import { activeUser } from '@app/lib/contracts';
import medicine from '@app/assets/tokens/medicine.png';
import diamond from '@app/assets/tokens/diamond.png';

import oneinchLogo from '@app/assets/tokens/1inch_logo.png';
import uniswapLogo from '@app/assets/tokens/uniswap_logo.png';
import synthetixLogo from '@app/assets/tokens/synthetix_logo.png';
import okxLogo from '@app/assets/tokens/okx_logo.svg';
import binanceLogo from '@app/assets/tokens/binance_logo.png';
import jupiterLogo from '@app/assets/tokens/jupiter-logo.png';

import {
  calculateProductAllocation,
  CompetitionTableData,
  EXCHANGE_LINKS,
  ProductAllocationActions,
  ProductData,
  ProductSharesPredictors,
  TOKEN_LOGOS,
  UPDATE_INTERVAL_MS,
} from '@app/lib/data';
import * as data from '@app/lib/data';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { Collapse, InputNumber } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import ProductChart from '@app/pages/ProductChart';
import { BigNumber, ethers } from 'ethers';
import { DECIMALS } from '@app/lib/contracts';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { InfoCard } from '@app/components/vaults-dashboard/infoCard/InfoCard';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import muscle from '@app/assets/tokens/muscle.png';
import reminder from '@app/assets/tokens/reminder.png';
import robot from '@app/assets/tokens/robot.png';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
type AlignType = 'left' | 'right' | 'center';
function greenRedDefault(input: number) {
  return (
    <span
      style={{
        color: input > 0 ? BASE_COLORS.lightgreen : input < 0 ? BASE_COLORS.red : 'default',
      }}
    >
      {input > 0 ? '+' + input.toFixed(1) : input.toFixed(1)}
      {}
    </span>
  );
}

const rowStyle = {
  cursor: 'pointer',
};

const kpiColumns = [
  {
    dataIndex: 'kpi',
    key: 'kpi',
    // render: (text) => <span>{text}</span>,
    align: 'right' as AlignType,
  },
  {
    dataIndex: 'value',
    key: 'value',
    render: (text: number) => <span>{greenRedDefault(text)}</span>,
    width: '50%',
  },
];

const productAllocationActionColumns: ColumnsType<ProductAllocationActions> = [
  {
    title: 'Asset',
    dataIndex: 'asset',
    key: 'asset',
    sorter: (a, b) => a.asset.localeCompare(b.asset),
    render: (asset: string) => (
      <>
        <img
          alt={asset}
          src={TOKEN_LOGOS[asset]}
          style={{
            height: '1rem',
            display: 'inline-block',
            borderRadius: '50%',
            verticalAlign: 'middle',
            marginRight: '0.5rem',
            lineHeight: '1rem',
          }}
        />
        <span
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            lineHeight: '1rem',
          }}
        >
          {asset}
        </span>
      </>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    sorter: (a, b) => a.action.localeCompare(b.action),
  },

  {
    title: 'Allocation (%)',
    dataIndex: 'percentage',
    key: 'percentage',
    render: (text: number) => (
      <span>
        {Math.abs(text).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    sorter: (a, b) => Math.abs(a.percentage) - Math.abs(b.percentage),
  },
];

interface IProductViewProps {
  selectedProduct: number;
  resetTrigger: boolean;
  connectedUser: string | undefined;
  nextCompTs: number;
}

const UPDOWN_MSG = `This allocation is based on a submission that does not require long and short positions to be balanced. The allocation may be skewed towards one side of the market.`;
const NEUTRAL_MSG = `This allocation is based on a submission that requires long and short positions to be balanced.`;
const LONG_MSG = `This allocation is created by preserving only the long positions of a submission that requires long and short positions to be balanced.`;

const ProductView: React.FC<IProductViewProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();
  const { id } = useParams<{ id: string }>();
  const theme = useAppSelector((state) => state.theme.theme);
  const [productDetails, setProductDetails] = React.useState<data.ProductData>(data.EMPTY_PRODUCT_DATA);
  const [btcHistory, setBtcHistory] = React.useState<data.HistoricalReturns>(data.BTC_HISTORICAL);
  const [spinning, setSpinning] = React.useState<boolean>(true);
  const [inputValue, setInputValue] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productAllocationActions, setProductAllocationActions] = useState<ProductAllocationActions[]>([]);
  const navigate = useNavigate();
  const [nextCompTs, setNextCompTs] = useState<number>(0);
  const [hasNotClicked, setHasNotClicked] = useState<boolean>(true);

  const sharePredictorColumns: ColumnsType<ProductSharesPredictors> = [
    {
      title: 'Percentage',
      dataIndex: 'share',
      key: 'share',
      render: (share) => (100 * share).toFixed(2) + '%',
      sorter: (a, b) => a.share - b.share,
    },
    {
      title: () => (
        <BaseTooltip
          title="Participants that contributed to this product"
          overlayInnerStyle={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 'xx-small',
            fontStyle: 'italic',
          }}
          placement={'leftBottom'}
        >
          <span>Predictor</span>
        </BaseTooltip>
      ),
      dataIndex: 'predictor',
      key: 'predictor',
      sorter: (a, b) => a.predictor.localeCompare(b.predictor),
      ellipsis: true,
    },
  ];

  const onRowClick = (record: ProductSharesPredictors, rowIndex: number | undefined) => ({
    onClick: () => {
      if (rowIndex === undefined) return;
      navigate(
        `/competition/${
          productDetails.strategy.toLowerCase() == 'long' ? 'neutral' : productDetails.strategy.toLowerCase()
        }/${record.predictor.toLowerCase()}`,
      );

      // selectors[idxs[rowIndex]]();
      // navigate(`/${'vaults'}`);
    },
    style: rowStyle,
  });

  const logoSelector = (market: string) => {
    if (market.toLowerCase().includes('1inch')) {
      return oneinchLogo;
    }
    if (market.toLowerCase().includes('uniswap')) {
      return uniswapLogo;
    }
    if (market.toLowerCase().includes('synthetix')) {
      return synthetixLogo;
    }
    if (market.toLowerCase().includes('binance')) {
      return binanceLogo;
    }
    if (market.toLowerCase().includes('okx')) {
      return okxLogo;
    }
    // if (market.toLowerCase().includes('jupiter')) {
    return jupiterLogo;
    // }
  };

  const exchangeSelector = (market: string) => {
    if (market.toLowerCase().includes('1inch')) {
      return '1inch';
    }
    if (market.toLowerCase().includes('uniswap')) {
      return 'Uniswap';
    }
    if (market.toLowerCase().includes('synthetix')) {
      return 'Synthetix';
    }
    if (market.toLowerCase().includes('binance')) {
      return 'Binance';
    }
    if (market.toLowerCase().includes('okx')) {
      return 'OKX';
    }
    // if (market.toLowerCase().includes('jupiter')) {
    return 'Jupiter';
    // }
  };

  const onActionClick = async () => {
    setIsLoading(true);
    const response = await data.calculateProductAllocation(Number(id), inputValue, props.connectedUser as string);
    setProductAllocationActions(response);
    // mock delay 1s
    setIsLoading(false);
    setHasNotClicked(false);
  };

  async function fetchProductDetails() {
    const res = await Promise.all([data.getProductData(Number(id)), data.getBtcHistory()]);

    setProductDetails(res[0] as data.ProductData);
    setBtcHistory(res[1] as data.HistoricalReturns);
  }

  // reset the input value to 0 and product allocation actions to empty on unmount
  React.useEffect(() => {
    return () => {
      setInputValue(0);
      setProductAllocationActions([]);
    };
  }, [props.resetTrigger]);

  // React.useEffect(() => {
  //   (async () => {
  //     setNextCompTs(await data.getNextCompTs());
  //   })();
  // }, []);

  React.useEffect(() => {
    (async () => {
      await fetchProductDetails();
      setNextCompTs(await data.getNextCompTs());
      // force sleep for 1 second
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      setSpinning(false);
    })();
    const intervalId = setInterval(async () => await fetchProductDetails(), UPDATE_INTERVAL_MS);
    // eslint-disable-next-line
    return () => clearInterval(intervalId);
  }, [props.selectedProduct]);

  const desktopLayout = (
    <BaseRow>
      <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '4vh' }}>
        <BaseButton type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/')}>
          Back to Marketplace
        </BaseButton>
      </BaseCol>
      <S.LeftSideCol xl={13} xxl={13}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol span={24}>
            <BaseSpin spinning={spinning}>
              <InfoCard
                title={`${productDetails.name}`}
                description={`${productDetails.strategy.toUpperCase()} | ${productDetails.market} | ${
                  productDetails.metamodel
                }\n
${
  productDetails.strategy.toLowerCase() == 'updown'
    ? UPDOWN_MSG
    : productDetails.strategy.toLowerCase() == 'long'
    ? LONG_MSG
    : NEUTRAL_MSG
}\n
The assets in this allocation are trade-able on ${productDetails.market}.\n
This allocation is derived from ${
                  productDetails.metamodel.toLowerCase() == 'individual' ? 'a single predictor' : 'multiple predictors'
                }.\n`}
              />
            </BaseSpin>
          </BaseCol>

          <BaseCol span={24}>
            <h1 style={{ fontWeight: 'bold' }}>Competition KPIs</h1>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={8} order={(isTablet && 1) || 0}>
                <AumCard
                  title={'AI Stage'}
                  value={productDetails.aiStage}
                  spinning={spinning}
                  unit={''}
                  greenRed={false}
                  tooltip={`N. of AI rounds participated`}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 3) || 0}>
                <AumCard
                  title={'Staked'}
                  value={productDetails.yiedlStaked}
                  spinning={spinning}
                  unit={'$YIEDL'}
                  greenRed={false}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 5) || 0}>
                <AumCard
                  title={'R/B Ratio'}
                  value={productDetails.rewardBurnRatio}
                  spinning={spinning}
                  unit={''}
                  greenRed={false}
                  tooltip={`N. of $Y gained/burned`}
                  isRbRatio={true}
                />
              </BaseCol>
            </BaseRow>
            <h1 style={{ fontWeight: 'bold', marginTop: '3vh' }}>Performance Report</h1>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={8} order={(isTablet && 11) || 0}>
                <AumCard title={'24h Returns'} value={productDetails.returns} spinning={spinning} unit={''} />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 13) || 0}>
                <AumCard title={'Sharpe'} value={productDetails.sharpe} spinning={spinning} unit={''} numberDecimals={2} />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 15) || 0}>
                <AumCard title={'Max DD'} value={productDetails.maxDrawdown} spinning={spinning} unit={''} />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol id="big-chart" span={24}>
            <ProductChart
              spinning={spinning}
              dayHistories={productDetails.historicalReturns['1d']}
              weekHistories={productDetails.historicalReturns['1w']}
              monthHistories={productDetails.historicalReturns['1m']}
              month3Histories={productDetails.historicalReturns['3m']}
              yearHistories={productDetails.historicalReturns['1y']}
              allHistories={productDetails.historicalReturns['all']}
              btcDayHistories={btcHistory['1d']}
              btcWeekHistories={btcHistory['1w']}
              btcMonthHistories={btcHistory['1m']}
              btcMonth3Histories={btcHistory['3m']}
              btcYearHistories={btcHistory['1y']}
              btcAllHistories={btcHistory['all']}
            />
          </BaseCol>
          <BaseCol span={24} order={25}>
            <h1 style={{ fontWeight: 'bold' }}>{`Contributions`}</h1>
            <BaseTable
              columns={sharePredictorColumns}
              dataSource={productDetails.predictors}
              loading={spinning}
              pagination={false}
              size={'small'}
              bordered={true}
              onRow={onRowClick}
            />
          </BaseCol>
        </BaseRow>

        {/*<References />*/}
      </S.LeftSideCol>

      <S.RightSideCol xl={11} xxl={11} style={{ marginTop: '0.3vh' }}>
        {nextCompTs == 0 ? (
          <BaseSpin spinning={spinning} />
        ) : (
          <BaseSpin spinning={spinning}>
            <BaseRow justify={'center'} align={'middle'} style={{ marginBottom: '1vh' }}>
              <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {`TAKE your`}
                {` `}
                <img
                  src={medicine}
                  alt={`red pill`}
                  style={{
                    width: '1.3em' /* Match the font size */,
                    height: '1.3em' /* Match the font size */,
                    verticalAlign: 'middle' /* Align with the middle of the text */,
                    display: 'inline-block' /* Make it a block element */,
                  }}
                />
                {`and LOOK at the NEXT`}
                {` `}
                <img
                  src={diamond}
                  alt={`diamond`}
                  style={{
                    width: '1.3em' /* Match the font size */,
                    height: '1.3em' /* Match the font size */,
                    verticalAlign: 'middle' /* Align with the middle of the text */,
                    display: 'inline-block' /* Make it a block element */,
                  }}
                />
              </h1>
            </BaseRow>
            {!isTablet ? (
              <>
                <BaseRow justify={'center'} align={'middle'}>
                  <BaseCol>
                    <h1
                      style={{
                        // color: themeObject[theme]["textMain"], // eslint-disable-line
                        fontWeight: 'bold',
                        // fontSize: '3vh',
                        textAlign: 'center',
                      }}
                    >
                      <img
                        src={reminder}
                        alt={`reminder`}
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                          verticalAlign: 'middle' /* Align with the middle of the text */,
                          display: 'inline-block' /* Make it a block element */,
                        }}
                      />
                      {' : FRESH portfolio ready in '}
                    </h1>
                  </BaseCol>
                </BaseRow>
                <BaseRow justify={'center'} align={'middle'}>
                  <BaseCol>
                    <FlipClockCountdown
                      to={nextCompTs}
                      digitBlockStyle={{
                        // adjust width according to screen size
                        width: '1.5rem',
                        fontSize: '1.5rem',
                        height: '2.5rem',
                        backgroundColor: themeObject[theme]['textMain'],
                        color: themeObject[theme]['alertTextColor'],
                      }}
                    />
                  </BaseCol>
                </BaseRow>
              </>
            ) : (
              <>
                <BaseRow
                  justify={'center'}
                  align={'middle'}
                  // style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <BaseCol
                  // style={{
                  //   display: 'flex',
                  // }}
                  >
                    <h1
                      style={{
                        // color: themeObject[theme]["textMain"], // eslint-disable-line
                        fontWeight: 'bold',
                        // fontSize: '3vh',
                        textAlign: 'center',
                        // display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={reminder}
                        alt={`reminder`}
                        style={{
                          width: '1.5rem',
                          height: '1.5rem',
                          verticalAlign: 'middle' /* Align with the middle of the text */,
                          display: 'inline-block' /* Make it a block element */,
                        }}
                      />
                      {' : FRESH portfolio ready in '}
                      <FlipClockCountdown
                        to={nextCompTs}
                        digitBlockStyle={{
                          backgroundColor: themeObject[theme]['textMain'],
                          color: themeObject[theme]['alertTextColor'],
                          fontSize: '1.3rem',
                          width: '1.3rem',
                          height: '2rem',
                        }}
                        labelStyle={{
                          fontSize: 'small',
                        }}
                        // separatorStyle={{
                        //   size: 'x-small',
                        // }}
                        // dividerStyle={{
                        //   height: '0.5em',
                        // }}
                        style={{
                          display: 'inline-flex',
                          // textAlign: 'center',
                          verticalAlign: 'middle',
                          margin: '0.5rem',
                        }}
                      />
                    </h1>
                  </BaseCol>
                </BaseRow>
              </>
            )}

            {props.connectedUser ? (
              <>
                <BaseRow gutter={[20, 20]} justify={'space-between'}>
                  <BaseCol span={24}>
                    {hasNotClicked ? (
                      <BaseButton
                        type="primary"
                        style={{
                          width: '100%',
                          marginBottom: '1.5rem',
                          marginTop: '1.0rem',
                          backgroundColor: themeObject[theme]['success'],
                          borderColor: themeObject[theme]['success'],
                        }}
                        loading={isLoading}
                        onClick={onActionClick}
                        // disabled={
                        //   inputValue <= 0
                        //   // logic to disable this according to subscription status
                        // }
                      >
                        {'Free Download'}
                      </BaseButton>
                    ) : null}
                  </BaseCol>
                </BaseRow>

                {productAllocationActions.length > 0 ? (
                  productAllocationActions.length == 1 && productAllocationActions[0].percentage == -1 ? (
                    <>
                      <BaseTable
                        columns={productAllocationActionColumns}
                        dataSource={[
                          {
                            asset: 'USDT',
                            action: 'hold',
                            usdValue: 0,
                            percentage: 100,
                          } as ProductAllocationActions,
                        ]}
                        loading={isLoading}
                        pagination={false}
                        size={'small'}
                        bordered={true}
                        style={{ marginTop: '1rem' }}
                      />

                      <span
                        style={{ marginTop: '1rem' }}
                      >{`No allocations available this week as the data scientist missed their submission. Please try another product.`}</span>
                    </>
                  ) : (
                    <>
                      <BaseTable
                        columns={productAllocationActionColumns}
                        dataSource={productAllocationActions}
                        loading={isLoading}
                        pagination={false}
                        size={'small'}
                        bordered={true}
                        style={{ marginTop: '1rem' }}
                      />
                      <BaseRow justify={'end'} style={{ marginTop: '1rem' }}>
                        <a href={EXCHANGE_LINKS[productDetails.market]} target="_blank" rel="noreferrer">
                          <span
                            style={{
                              fontSize: 'small',
                              fontStyle: 'italic',
                              marginLeft: '2rem',
                              lineHeight: '1rem',
                              verticalAlign: 'middle' /* Align with the middle of the text */,
                            }}
                          >
                            DEGEN on {exchangeSelector(productDetails.market)}{' '}
                            <img
                              src={logoSelector(productDetails.market)}
                              alt={exchangeSelector(productDetails.market)}
                              style={{
                                width: '1.5em' /* Match the font size */,
                                height: '1.5em' /* Match the font size */,
                                // verticalAlign: 'text-bottom' /* Align with the middle of the text */,
                                display: 'inline-block' /* Make it a block element */,
                              }}
                            />
                          </span>
                        </a>
                      </BaseRow>
                    </>
                  )
                ) : null}
              </>
            ) : (
              <BaseRow>
                <span style={{ marginTop: '1rem' }}>Please connect your wallet to view allocation.</span>
              </BaseRow>
            )}
          </BaseSpin>
        )}
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <>
      <BaseButton type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/')}>
        Back to Marketplace
      </BaseButton>
      <BaseRow gutter={[20, 20]}>
        <BaseCol span={24}>
          <BaseSpin spinning={spinning}>
            <InfoCard
              title={`${productDetails.name}`}
              description={`${productDetails.strategy} | ${productDetails.market} | ${productDetails.metamodel}\n
${
  productDetails.strategy.toLowerCase() == 'updown'
    ? UPDOWN_MSG
    : productDetails.strategy.toLowerCase() == 'long'
    ? LONG_MSG
    : NEUTRAL_MSG
}\n
The assets in this allocation are trade-able on ${productDetails.market}.\n
This allocation is derived from ${
                productDetails.metamodel.toLowerCase() == 'individual' ? 'a single predictor' : 'multiple predictors'
              }.\n`}
            />
          </BaseSpin>
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 1) || 0}>
          {nextCompTs == 0 ? (
            <BaseSpin spinning={spinning} />
          ) : (
            <BaseSpin spinning={spinning}>
              <BaseRow justify={'center'} align={'middle'} style={{ marginBottom: '1vh' }}>
                <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {`TAKE your`}
                  {` `}
                  <img
                    src={medicine}
                    alt={`red pill`}
                    style={{
                      width: '1.3em' /* Match the font size */,
                      height: '1.3em' /* Match the font size */,
                      verticalAlign: 'middle' /* Align with the middle of the text */,
                      display: 'inline-block' /* Make it a block element */,
                    }}
                  />
                  {`and LOOK at the NEXT`}
                  {` `}
                  <img
                    src={diamond}
                    alt={`diamond`}
                    style={{
                      width: '1.3em' /* Match the font size */,
                      height: '1.3em' /* Match the font size */,
                      verticalAlign: 'middle' /* Align with the middle of the text */,
                      display: 'inline-block' /* Make it a block element */,
                    }}
                  />
                </h1>
              </BaseRow>

              {!isTablet ? (
                <>
                  <BaseRow justify={'center'} align={'middle'}>
                    <BaseCol>
                      <h1
                        style={{
                          // color: themeObject[theme]["textMain"], // eslint-disable-line
                          fontWeight: 'bold',
                          // fontSize: '3vh',
                          textAlign: 'center',
                        }}
                      >
                        <img
                          src={reminder}
                          alt={`reminder`}
                          style={{
                            width: '1.5em',
                            height: '1.5em',
                            verticalAlign: 'middle' /* Align with the middle of the text */,
                            display: 'inline-block' /* Make it a block element */,
                          }}
                        />
                        {' : FRESH portfolio ready in '}
                      </h1>
                    </BaseCol>
                  </BaseRow>
                  <BaseRow justify={'center'} align={'middle'}>
                    <BaseCol>
                      <FlipClockCountdown
                        to={nextCompTs}
                        digitBlockStyle={{
                          // adjust width according to screen size
                          width: '1.5rem',
                          fontSize: '1.5rem',
                          height: '2.5rem',
                          backgroundColor: themeObject[theme]['textMain'],
                          color: themeObject[theme]['alertTextColor'],
                        }}
                      />
                    </BaseCol>
                  </BaseRow>
                </>
              ) : (
                <>
                  <BaseRow
                    justify={'center'}
                    align={'middle'}
                    // style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <BaseCol
                    // style={{
                    //   display: 'flex',
                    // }}
                    >
                      <h1
                        style={{
                          // color: themeObject[theme]["textMain"], // eslint-disable-line
                          fontWeight: 'bold',
                          // fontSize: '3vh',
                          textAlign: 'center',
                          // display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={reminder}
                          alt={`reminder`}
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            verticalAlign: 'middle' /* Align with the middle of the text */,
                            display: 'inline-block' /* Make it a block element */,
                          }}
                        />
                        {' : FRESH portfolio ready in '}
                        <FlipClockCountdown
                          to={nextCompTs}
                          digitBlockStyle={{
                            backgroundColor: themeObject[theme]['textMain'],
                            color: themeObject[theme]['alertTextColor'],
                            fontSize: '1.3rem',
                            width: '1.3rem',
                            height: '2rem',
                          }}
                          labelStyle={{
                            fontSize: 'small',
                          }}
                          // separatorStyle={{
                          //   size: 'x-small',
                          // }}
                          // dividerStyle={{
                          //   height: '0.5em',
                          // }}
                          style={{
                            display: 'inline-flex',
                            // textAlign: 'center',
                            verticalAlign: 'middle',
                            margin: '0.5rem',
                          }}
                        />
                      </h1>
                    </BaseCol>
                  </BaseRow>
                </>
              )}

              {props.connectedUser ? (
                <>
                  <BaseRow gutter={[20, 20]} justify={'space-between'}>
                    <BaseCol span={24}>
                      {hasNotClicked ? (
                        <BaseButton
                          type="primary"
                          style={{
                            width: '100%',
                            marginBottom: '1.5rem',
                            marginTop: '1.0rem',
                            backgroundColor: themeObject[theme]['success'],
                            borderColor: themeObject[theme]['success'],
                          }}
                          loading={isLoading}
                          onClick={onActionClick}
                          // disabled={
                          //   inputValue <= 0
                          //   // logic to disable this according to subscription status
                          // }
                        >
                          {'Free Download'}
                        </BaseButton>
                      ) : null}
                    </BaseCol>
                  </BaseRow>

                  {productAllocationActions.length > 0 ? (
                    productAllocationActions.length == 1 && productAllocationActions[0].percentage == -1 ? (
                      <>
                        <BaseTable
                          columns={productAllocationActionColumns}
                          dataSource={[
                            {
                              asset: 'USDT',
                              action: 'hold',
                              usdValue: 0,
                              percentage: 100,
                            } as ProductAllocationActions,
                          ]}
                          loading={isLoading}
                          pagination={false}
                          size={'small'}
                          bordered={true}
                          style={{ marginTop: '1rem' }}
                        />

                        <span
                          style={{ marginTop: '1rem' }}
                        >{`No allocations available this week as the data scientist missed their submission. Please try another product.`}</span>
                      </>
                    ) : (
                      <>
                        <BaseTable
                          columns={productAllocationActionColumns}
                          dataSource={productAllocationActions}
                          loading={isLoading}
                          pagination={false}
                          size={'small'}
                          bordered={true}
                          style={{ marginTop: '1rem' }}
                        />
                        <BaseRow justify={'end'} style={{ marginTop: '1rem' }}>
                          <a href={EXCHANGE_LINKS[productDetails.market]} target="_blank" rel="noreferrer">
                            <span
                              style={{
                                fontSize: 'small',
                                fontStyle: 'italic',
                                marginLeft: '2rem',
                                lineHeight: '1rem',
                                verticalAlign: 'middle' /* Align with the middle of the text */,
                              }}
                            >
                              DEGEN on {exchangeSelector(productDetails.market)}{' '}
                              <img
                                src={logoSelector(productDetails.market)}
                                alt={exchangeSelector(productDetails.market)}
                                style={{
                                  width: '1.5em' /* Match the font size */,
                                  height: '1.5em' /* Match the font size */,
                                  verticalAlign: 'middle' /* Align with the middle of the text */,
                                  display: 'inline-block' /* Make it a block element */,
                                  lineHeight: '1rem',
                                }}
                              />
                            </span>
                          </a>
                        </BaseRow>
                      </>
                    )
                  ) : null}
                </>
              ) : (
                <BaseRow>
                  <span style={{ marginTop: '1rem' }}>Please connect your wallet to view allocation.</span>
                </BaseRow>
              )}
            </BaseSpin>
          )}
        </BaseCol>

        {/*============================*/}
        <BaseCol span={24} order={(isTablet && 2) || 0}>
          <h1 style={{ fontWeight: 'bold' }}>Competition KPIs</h1>
          <BaseRow gutter={[20, 20]} justify={'space-between'}>
            <BaseCol xs={24} md={8} order={(isTablet && 3) || 0}>
              <AumCard
                title={'AI Stage'}
                value={productDetails.aiStage}
                spinning={spinning}
                unit={''}
                greenRed={false}
                tooltip={`N. of AI rounds participated`}
              />
            </BaseCol>
            <BaseCol xs={24} md={8} order={(isTablet && 5) || 0}>
              <AumCard
                title={'Staked'}
                value={productDetails.yiedlStaked}
                spinning={spinning}
                unit={'$YIEDL'}
                greenRed={false}
              />
            </BaseCol>
            <BaseCol xs={24} md={8} order={(isTablet && 7) || 0}>
              <AumCard
                title={'R/B Ratio'}
                value={productDetails.rewardBurnRatio}
                spinning={spinning}
                unit={''}
                greenRed={false}
                tooltip={`N. of $Y gained/burned`}
                isRbRatio={true}
              />
            </BaseCol>
          </BaseRow>

          <h1 style={{ fontWeight: 'bold', marginTop: '3vh' }}>Performance Report</h1>
          <BaseRow gutter={[20, 20]} justify={'space-between'}>
            <BaseCol xs={24} md={8} order={(isTablet && 10) || 0}>
              <AumCard title={'24h Returns'} value={productDetails.returns} spinning={spinning} unit={''} />
            </BaseCol>
            <BaseCol xs={24} md={8} order={(isTablet && 11) || 0}>
              <AumCard title={'Sharpe'} value={productDetails.sharpe} spinning={spinning} unit={''} numberDecimals={2} />
            </BaseCol>
            <BaseCol xs={24} md={8} order={(isTablet && 12) || 0}>
              <AumCard title={'Max DD'} value={productDetails.maxDrawdown} spinning={spinning} unit={''} />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol id="big-chart" md={24} xs={24} order={(isTablet && 15) || 0}>
          <ProductChart
            spinning={spinning}
            dayHistories={productDetails.historicalReturns['1d']}
            weekHistories={productDetails.historicalReturns['1w']}
            monthHistories={productDetails.historicalReturns['1m']}
            month3Histories={productDetails.historicalReturns['3m']}
            yearHistories={productDetails.historicalReturns['1y']}
            allHistories={productDetails.historicalReturns['all']}
            btcDayHistories={btcHistory['1d']}
            btcWeekHistories={btcHistory['1w']}
            btcMonthHistories={btcHistory['1m']}
            btcMonth3Histories={btcHistory['3m']}
            btcYearHistories={btcHistory['1y']}
            btcAllHistories={btcHistory['all']}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 20) || 0}>
          <h1 style={{ fontWeight: 'bold' }}>{`Contributions`}</h1>
          <BaseTable
            columns={sharePredictorColumns}
            dataSource={productDetails.predictors}
            loading={spinning}
            pagination={false}
            size={'small'}
            bordered={true}
            onRow={onRowClick}
          />
        </BaseCol>
      </BaseRow>
    </>
  );

  return (
    <>
      <PageTitle>{`Product: ${id}`}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ProductView;
