import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { ACTUAL_DATA_EPOCH_MS } from '@app/lib/contracts';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Simulate } from 'react-dom/test-utils';
import compositionEnd = Simulate.compositionEnd;
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { CompetitionDataPoint } from '@app/lib/data';
const YEAR_MS = 52 * 7 * 24 * 60 * 60 * 1000;

interface IPartiipantChartProps {
  data: Array<CompetitionDataPoint>;
  spinning: boolean;
  title: string;
  valueName: string;
  unit: string;
}

export const ParticipantChart: React.FC<IPartiipantChartProps> = (props: IPartiipantChartProps) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const option = {
    color: themeObject[theme]['success'],
    animationDuration: 700,
    tooltip: {
      formatter: function (params: any) {
        const cycle = params[0].axisValue.toFixed(0);

        return `<b>${params[0].value[1].toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })} ${props.unit}</b><br/>Round: ${cycle}`;
      },
      textStyle: {
        align: 'right', // Align text to the right
      },
      trigger: 'axis',
    },
    xAxis: {
      boundaryGap: false,
      type: 'value',
      axisLabel: {
        show: true,
        // // interval: 'auto', // timeframe == 0 ? 99 : timeframe == 3 ? 79 : 55,
        // interval: 99,
        hideOverlap: true,
        rotate: 25,
        textStyle: {
          fontSize: 9,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      nameLocation: 'middle',
      nameGap: 40,
      nameRotate: 90,
      scale: true,
      animation: true,
      animationDurationUpdate: 0,
      splitLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
    },
    // grid: {
    //   left: 65,
    //   right: 150,
    //   top: 20,
    //   bottom: 30,
    // },
    grid: {
      containLabel: true,
      left: 65,
      bottom: 10,
      right: 30,
    },
    series: [
      {
        name: props.valueName,
        data: props.data.map((item) => ({
          value: [item.cycle, item.value],
        })),
        type: 'line',
        smooth: true,
        smoothMonotone: 'x',
        symbol: 'none',
        animation: true,
        animationDuration: 1000,
        animationDurationUpdate: 0,
      },
    ],
  };

  return (
    <BaseCard padding="0 0 0.875rem">
      <BaseRow
        gutter={[20, 20]}
        align="middle"
        style={{ justifyContent: 'space-between', paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '2vh' }}
      >
        <BaseCol>
          <h1 style={{ fontWeight: 'bold' }}>{`${props.title}`}</h1>
        </BaseCol>
      </BaseRow>

      <BaseSpin spinning={props.spinning}>
        {props.spinning ? null : <BaseChart option={option} height="24rem" />}
      </BaseSpin>
    </BaseCard>
  );
};

export default ParticipantChart;
