import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
// import VaultsDashboardPage from '@app/pages/DashboardPages/VaultsDashboardPage';
import ProductView from '@app/pages/ProductView';
import ParticipantView from '@app/pages/ParticipantView';
import TokenView from '@app/pages/TokenView';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const TransactionsPage = React.lazy(() => import('@app/pages/TransactionsPage'));
const StatusPage = React.lazy(() => import('@app/pages/StatusPage'));
const OverviewPage = React.lazy(() => import('@app/pages/OverviewPage'));
const CompetitionPage = React.lazy(() => import('@app/pages/CompetitionPage'));
const MyPortfolioPage = React.lazy(() => import('@app/pages/MyPortfolioPage'));
const LeaderboardPage = React.lazy(() => import('@app/pages/LeaderboardPage'));
const AirdropPage = React.lazy(() => import('@app/pages/AirdropPage'));
const AboutCompetitionPage = React.lazy(() => import('@app/pages/AboutCompetitionPage'));
const TermsOfUsePage = React.lazy(() => import('@app/pages/TermsOfUse'));
const PrivacyPolicyPage = React.lazy(() => import('@app/pages/PrivacyPolicy'));
const AccountPage = React.lazy(() => import('@app/pages/AccountPage'));

// import SwapPage from '@app/pages/SwapPage';
import { CompletedRequests, OperationsCache, Portfolio, Request } from '@app/lib/contracts';
import {
  AggregatedAllocations,
  AggregatedData,
  AssetBullishIndex,
  CB,
  CompetitionDataPoint,
  CompetitionTableData,
  MarketplaceData,
  ProductData,
} from '@app/lib/data';

export const HOME_PATH = '/';
export const VAULTS_PATH = '/vaults';

// const VaultsDashboard = VaultsDashboardPage;

const Transactions = withLoading(TransactionsPage);
const Status = withLoading(StatusPage);
const Overview = withLoading(OverviewPage);
const Competition = withLoading(CompetitionPage);
const Account = withLoading(AccountPage);
const MyPortfolio = withLoading(MyPortfolioPage);
// const Swap = withLoading(SwapPage);
const Leaderboard = withLoading(LeaderboardPage);
const Airdrop = withLoading(AirdropPage);
const AboutCompetition = withLoading(AboutCompetitionPage);
const TermsOfUse = withLoading(TermsOfUsePage);
const PrivacyPolicy = withLoading(PrivacyPolicyPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const AuthLayoutFallback = withLoading(AuthLayout);

export interface IAppRouterProps {
  spinning: boolean;
  connected: boolean;
  marketFeed: Array<any>;
  marketplaceTableData: Array<MarketplaceData>;
  setSelectedProduct: any;
  selectedProduct: number;
  setProductDetails: any;
  productDetails: ProductData;
  resetTrigger: boolean;
  setResetTrigger: any;
  cexDexSelection: string;
  setCexDexSelection: any;
  networkSelection: string;
  setNetworkSelection: any;
  fullMarketplaceData: Array<MarketplaceData>;
  connectedUser: string | undefined;
  competitionTableData: Array<CompetitionTableData>;
  compAggDataList: Array<AggregatedData>;
  relativeGainsData: Array<Array<Array<CompetitionDataPoint>>>;
  aggAllocs: AggregatedAllocations;
  selectedCbs: { [key in CB]: boolean };
  setSelectedCbs: any;
  assetStrengths: AssetBullishIndex[];
  nextCompTs: number;
}

export const AppRouter: React.FC<IAppRouterProps> = (props: IAppRouterProps) => {
  // console.log('AppRouter props', props);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_PATH} element={<MainLayout marketFeed={props.marketFeed} connected={props.connected} />}>
          {/*<Route index element={<NftDashboard />} />*/}
          <Route
            path="/product/:id"
            element={
              <ProductView
                selectedProduct={props.selectedProduct}
                resetTrigger={props.resetTrigger}
                connectedUser={props.connectedUser}
                nextCompTs={props.nextCompTs}
              />
            }
          />
          <Route path="/competition/:compName/:id" element={<ParticipantView resetTrigger={props.resetTrigger} />} />
          <Route
            path="/token/:token"
            element={<TokenView resetTrigger={props.resetTrigger} assetStrengths={props.assetStrengths} />}
          />
          <Route
            // path={'/overview'}
            index
            element={
              <Overview
                loading={props.spinning}
                marketplaceData={props.marketplaceTableData}
                setSelectedProduct={props.setSelectedProduct}
                selectedProduct={props.selectedProduct}
                productDetails={props.productDetails}
                setProductDetails={props.setProductDetails}
                resetTrigger={props.resetTrigger}
                setResetTrigger={props.setResetTrigger}
                cexDexSelection={props.cexDexSelection}
                setCexDexSelection={props.setCexDexSelection}
                networkSelection={props.networkSelection}
                setNetworkSelection={props.setNetworkSelection}
                fullMarketplaceData={props.fullMarketplaceData}
                selectedCbs={props.selectedCbs}
                setSelectedCbs={props.setSelectedCbs}
                assetStrength={props.assetStrengths}
                nextCompTs={props.nextCompTs}
              />
            }
          />
          {/*<Route path={'/swap'} element={<Swap loading={props.spinning} />} />*/}
          {/*<Route path={'competition'}>*/}
          {/*  <Route path={'about'} element={<AboutCompetition />} />*/}
          {/*  <Route path={'leaderboard'} element={<Leaderboard />} />*/}
          {/*  <Route path={'airdrop'} element={<Airdrop connected={props.connected} spinning={props.spinning} />} />*/}
          {/*</Route>*/}
          <Route
            path={'/competition'}
            element={
              <Competition
                loading={props.spinning}
                competitionTableData={props.competitionTableData}
                compAggDataList={props.compAggDataList}
                relativeGainsData={props.relativeGainsData}
                aggAllocs={props.aggAllocs}
              />
            }
          />

          <Route path={'/competition/join'} element={<AboutCompetition />} />
          <Route
            path={'/competition/airdrop'}
            element={
              <Airdrop
                spinning={props.spinning}
                connected={props.connectedUser !== undefined}
                connectedUser={props.connectedUser}
              />
            }
          />
          <Route path={'/account'} element={<Account />} />

          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/status" element={<Status />} />
      </Routes>
    </BrowserRouter>
  );
};
